<template>
    <div class="preset-filter-select">
        <div
            v-click-outside="clickOutside"
            class="form-select-preset"
            :class="{ 'grey-border' : isOpenItemsList }"
        >
            <fieldset :class="isOpenItemsList ? 'form-select-preset__fieldset active' : 'form-select-preset__fieldset'">
                <div class="form-select-preset__input-field">
                    <span
                        :class="isOpenItemsList ? 'form-select-preset-list-open' : 'form-select-preset-list-close'"
                        @click="isOpenItemsList = !isOpenItemsList"
                    />
                    <input
                        class="form-select-preset__input orders-details-tabs-select__input"
                        :value="presetTitle"
                        type="text"
                        :name="name"
                        :placeholder="name"
                        :disabled="disabled"
                        readonly
                        @click.self="isOpenItemsList = !isOpenItemsList"
                        @focus="$event.target.select()"
                    >
                    <span
                        :class="{ 'form-select-preset-close-preset' : value }"
                        @click.stop="removeItem(null)"
                    />
                    <span class="form-select-preset__counter form-select-preset__counter--base">
                        {{ totalPresetsAmount }}
                    </span>
                    <span
                        v-if="counterMoney"
                        class="form-select-preset__money"
                    >
                        <span v-if="!isLoading">
                            {{ counterMoney | money }}
                        </span>
                        <template v-else>
                            <div><PresetLoader /></div>
                        </template>
                    </span>
                    <!--                    <span-->
                    <!--                        :class="{'active': isOpenItemsList}"-->
                    <!--                        class="form-select-preset__arrow"-->
                    <!--                        @click="isOpenItemsList = !isOpenItemsList"-->
                    <!--                    >-->
                    <!--                        <chevron-down />-->
                    <!--                    </span>-->
                </div>
                <Transition name="slide-fade">
                    <ul
                        v-if="isOpenItemsList"
                        class="form-select-preset__list"
                    >
                        <span
                            class="form-select-preset__list-title"
                            @click.stop="isOpenItemsList = false"
                        >
                            Status
                        </span>
                        <template v-for="(item, index) in items">
                            <li
                                v-if="item.counters.count"
                                :key="index"
                                class="form-select-preset__list-item"
                                :class="{'active-tab-link': item.value === value}"
                                @click="selectItem(item.value)"
                            >
                                <span class="form-select-preset__list-item-text">
                                    {{ item.title }}
                                    <span
                                        :class="{'form-select-preset-close-preset' : item.value === value }"
                                        @click.stop="removeItem(null)"
                                    />
                                </span>
                                <span class="form-select-preset__counter">{{ item.counters.count }}</span>
                            </li>
                        </template>
                    </ul>
                </Transition>
            </fieldset>
        </div>
    </div>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin.js'

import PresetLoader from '@/components/common/PresetLoader';
// import ChevronDown from 'mdi-vue/ChevronDown'

export default {
    name: 'PresetsFilterSelect',
    components: {
        // ChevronDown,
        PresetLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        items: { type: Array },
        name: {
            required: true,
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        counterMoney: {
            type: [String, Number],
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        total: {
            type: Number
        }
    },
    data() {
        return {
            isOpenItemsList: false
        }
    },
    computed: {
        presetTitle() {
            const isExist = this.items.some((el) => el.value === this.value);
            if (isExist) {
                return this.items.find((element) => element.value === this.value).title;
            }
            return 'All'
        },
        totalPresetsAmount() {
            if (this.value) {
                return this.items.find((element) => element.value === this.value).counters.count
            }
            return this.total
        }
    },
    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.isOpenItemsList = false;
        },
        removeItem(value) {
            this.$emit('input', value);
            this.isOpenItemsList = false;
        },
        clickOutside() {
            this.isOpenItemsList = false
        }
    }
};
</script>

<style lang="scss">
.form-select-preset {
    outline: none;
    box-shadow: inset 0px -1px 0 0 #e7ecf4;
    max-height: 52px!important;
    width: 100%;

    &.success-border {
        border: $input-border-success;
    }
    &.grey-border {
        //border: $input-border;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }
    &__fieldset {
        position: relative;
        border: none ;
        outline: none;
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: rgba($main-color, 0.1);
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 12px;
        width: 100%;
        cursor: pointer;
        border: none ;
        outline: none;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: 700;
        color: #435A7D;
        max-height: 48px;
        height: 48px;
    }
    &__input-field {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
        .active {
            background-color: #fff;
            svg {
                transform: rotate(-180deg);
            }
        }
        @include media480max {
            padding: 0 15px;
        }
    }
    //&__arrow{
    //    margin-right: 15px;
    //}
    &__list {
        position: fixed;
        overflow: hidden;
        background-color: $white;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        overscroll-behavior: contain;
        z-index: 100;
        .active-tab-link{
            background: #F2F6FF;
        }
        .close{
            //margin-left: 20px;
        }
    }
    &__list-title {
        height: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #111720;
        padding: 35px 35px 35px 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e7ecf4;
        margin-bottom: 20px;
        position: relative;
        &::before{
            content: '';
            width: 20px;
            height: 15px;
            background: url('~@/assets/img/svg/arrow.svg') no-repeat;
            position: absolute;
            left: 25px;
        }
    }
    &__counter{
        font-weight: bold;
        color: #fff;
        background-color: $main-color;
        padding: 1px 7px 0;
        border-radius: 50px;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        margin-left: 15px;
        height: 21px;
        &--base{
            margin-right: 15px;
            @include media480max {
                margin-right: 0;
            }
        }
    }
    &__money{
        position: relative;
        display: inline-flex;
        align-items: baseline;
        padding: 7px 10px;
        font-size: 16px;
        font-weight: 700;
        color: $main-color;
        border-left: 2px solid #EAEAEA;
        svg {
            height: 25px;
        }
        @include media480max {
            padding-right: 0;
            padding-left: 15px;
            margin-left: 15px;
        }
    }
    &__list-item {
        padding: 10px 15px;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 15px 1px 15px;
        &:hover {
            background-color: lightgrey;
        }
    }
    &__list-item-text {
        width: 200px;
        font-weight: 700;
        color: #435A7D;
        min-width: 130px;
    }
    &__list-item-text .form-select-preset-close-preset:after {
        width: 10px;
        height: 10px;
        margin-left: 10px;
    }
}

.preset-filter-select{
    margin-top: 10px;
    box-shadow: 0 0 10px 3px rgb(25 50 71 / 25%);
    display: none;
    @media screen and (max-width: 768px) {
        display: flex;
    }
    @include media480max {
        margin-top: 0;
    }
}

.form-select-preset-close-preset{
    cursor: pointer;
    &:after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 15px;
        background: url("~@/assets/img/svg/close-dark-blue.svg") no-repeat center;
        @include media480max {
            margin-right: 0;
            margin-top: 3px;
        }
    }
}

.form-select-preset-list-close{
    cursor: pointer;
    border-right: 2px solid #EAEAEA;
    &:after {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        margin: 0 10px;
        background: url("~@/assets/img/svg/menu-preset.svg") no-repeat center;
        transform: rotate(0deg);
        transition: .5s;
        @include media480max {
            margin-left: 0;
        }
    }
}

.form-select-preset-list-open{
    cursor: pointer;
    border-right: 2px solid #EAEAEA;
    &:after {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        margin: 0 10px;
        background: url("~@/assets/img/svg/menu-preset.svg") no-repeat center;
        transform: rotate(90deg);
        transition: .5s;
    }
}

</style>
