<template>
    <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z"
            :stroke="color"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'FilterIcon',
    props: {
        color: {
            type: String,
            default: '#FF690C'
        }
    }
}
</script>
<style lang='scss' scoped>
svg path {
    stroke: $main-color;
}
</style>
